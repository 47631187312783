import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select, Avatar, Menu, Space, message, Popover, Divider, Tag, Button } from 'antd';
import type { MenuProps, SelectProps } from 'antd';
import _ from 'lodash';
import {
  AppstoreOutlined,
  SettingOutlined,
  DownOutlined,
  SmileOutlined,
  UserOutlined,
  UnlockOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import logo from '@/assets/imgs/logo.png';
import searchIcon from '@/assets/icon/搜索小.svg';
import tour from '@/assets/imgs/tour.png';
import remindIcon from '@/assets/icon/提醒.svg';
import pullDon from '@/assets/icon/下拉.svg';
import style from './index.module.less';
import {
  selectTheme,
  setTheme,
  selectUserInfo,
  selectAvatar,
  selectRoutingAddress,
  setRoutingAddress,
  setCheckedTag,
  selectCheckedTag,
  logout
} from '@/store/modules/app';
import SelectBox from '@/components/Select'
import { homeApi } from '@/services'
import JumpFunction from '@/components/JumpFunction';
import { removeToken } from '@/utils/auth'
import UserCenter from '@/pages/UserCenter'
import { MENU_LIST } from '@/config/menu'
import avatar from '@/assets/icon/头像.svg';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const types = [{ type: 0, name: '企业', key: 'compCode' }, { type: 1, name: '债券', key: 'securityId' }] // 类型（企业:0/债券:1）


function BlankHeader(props: any) {
  const [current, setCurrent] = useState('homePage');
  const [widthUp, setwidthUp] = useState(false)
  const theme = useSelector(selectTheme);
  const userInfo: any = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const RoutingAddress = useSelector(selectRoutingAddress);
  const CheckedTag = useSelector(selectCheckedTag);
  const history = useHistory();
  const [commonOptions, setCommonOptions] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false) // 个人中心弹框显示隐藏
  const [flagUrl, setflagUrl] = useState(0)
  useEffect(() => {
    if (flagUrl == 0) {
      const urlPitchOn = localStorage.getItem('pageName')
      setCurrent(urlPitchOn != '' ? urlPitchOn : 'homePage');
      setflagUrl(1)
    } else {
      localStorage.setItem("pageName", String(CheckedTag));
      setCurrent(CheckedTag != '' ? CheckedTag : 'homePage');
    }
    return setCurrent(CheckedTag);
  }, [CheckedTag]);

  const [data, setData] = useState<SelectProps['options']>([]);
  const [value, setValue] = useState<string>();
  const [searchResult, setSearchResult] = useState([]);
  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  useEffect(() => {
    if (props.reset && value) {
      setValue("");
    }
  }, [props.reset]);



  const fetch = (value: string, callback: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = () => {
      const commonSearch = async () => {
        const res = await homeApi.commonSearch({ name: value })

        if (res.return_code === '0') {
          if (currentValue === value && res?.dataList && res?.dataList?.length) {
            let data = []
            setSearchResult(res.dataList)
            types.forEach(t => {
              data.push({
                label: <div style={{ height: '30px', lineHeight: '30px', textIndent: '5px', background: 'rgba(58, 58, 68,1)', fontSize: '13px' }}>{t.name}</div>,
                options: res?.dataList.map((item: any) => {
                  if (item?.targetType === t.type) {
                    return ({
                      ...item,
                      value: item[t.key],
                      label: <JumpFunction
                        key={item.targetType === 1 ? item.secode : item.targetCode}
                        val={{
                          name: item.name,
                          key: item.targetType === 1 ? item.secode : item.targetCode,
                          detailly: { ...item, creditCode: item?.targetCode, secode: item?.secode },
                          type: item.targetType === 1 ? 'SurvivingBondDetails' : 'QEdetails'
                        }}
                      >{highlightText(item.name, value)}
                      </JumpFunction>
                      // label: <a style={{ color: '#fff' }}>{item.name}</a>
                    })
                  }
                  return ''
                }).filter(k => k !== '')
              })
            })
            data = data.filter(item => item.options.length)
            callback(data);
          }
        } else {
          message.error(res?.return_msg || '请求出错')
        }
      }
      commonSearch()
    }

    if (value) {
      timeout = setTimeout(fake, 300);
    } else {
      callback([]);
    }
  };
  const handleChange: any = (newValue: string, option) => {
    console.log(newValue)
    setValue(newValue);
    // props?.onChange(newValue, option, value);
  };
  const filterOption = (inputValue, option) => {
    //搜索不区分大小写
    // return (
    //   data.children[0].text
    //   .toLowerCase()
    //   .indexOf(inputValue.toLowerCase()) >= 0
    // )
    return data
  }


  // useEffect(() => {
  //   console.log(RoutingAddress)
  // }, [RoutingAddress])
  const toggleTheme = () => {
    dispatch(setTheme(theme === 'dark' ? 'white' : 'dark'));
  };
  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    const urlDatail: any = _.cloneDeep(RoutingAddress);
    const flag = RoutingAddress.filter((v: any) => {
      return v.key == e.key || v == e.key;
    });
    dispatch(setCheckedTag(e.key));
    if (flag.length == 0) {
      if (e.key == 'QErate') {
        urlDatail.push({ name: 'QE评级', key: e.key, detailly: null });
      } else if (e.key == 'RatingChange') {
        urlDatail.push({ name: '评级变动', key: e.key, detailly: null });
      } else if (e.key == 'AreaData') {
        urlDatail.push({ name: '区域数据', key: e.key, detailly: null });
      } else if (e.key == 'BusinessData') {
        urlDatail.push({ name: '经营数据', key: e.key, detailly: null });
      } else if (e.key == 'RegionalSpread') {
        urlDatail.push({ name: '区域利差', key: e.key, detailly: null });
      } else if (e.key == 'IndustrySpread') {
        urlDatail.push({ name: '行业利差', key: e.key, detailly: null });
      } else if (e.key == 'ResearchReport') {
        urlDatail.push({ name: '研究报告', key: e.key, detailly: null });
      } else if (e.key == 'BreakaAontract') {
        urlDatail.push({ name: '违约专题', key: e.key, detailly: null });
      } else if (e.key == 'NonStandardRisk') {
        urlDatail.push({ name: '非标风险', key: e.key, detailly: null });
      } else if (e.key == 'CommercialBillsOverdue') {
        urlDatail.push({ name: '商票逾期', key: e.key, detailly: null });
      } else if (e.key == 'investDecision') {
        urlDatail.push({ name: '可投库', key: e.key, detailly: null });
      } else if (e.key == 'ReceptionRoom') {
        urlDatail.push({ name: '投资人会客厅', key: e.key, detailly: null });
      } else if (e.key == 'Announcement') {
        urlDatail.push({ name: '公告信息', key: e.key, detailly: null });
      } else if (e.key == 'OptionalMonitoring') {
        urlDatail.push({ name: '自选监控', key: e.key, detailly: null });
      } else if (e.key == 'MacroeconomicAnalysis') {
        urlDatail.push({ name: '宏观研究', key: e.key, detailly: null });
      } else if (e.key == 'Wisdom') {
        urlDatail.push({ name: '智慧城投', key: e.key, detailly: null });
      } else if (e.key == 'SmartFinance') {
        urlDatail.push({ name: '智慧金融', key: e.key, detailly: null });
      } else if (e.key == 'jingxuan') {
        urlDatail.push({ name: '精选舆情', key: e.key, detailly: null });
      } else if (e.key == 'gonggao') {
        urlDatail.push({ name: '公告信息', key: e.key, detailly: null });
      } else if (e.key == 'zhengce') {
        urlDatail.push({ name: '政策热点', key: e.key, detailly: null });
      } else if (e.key == 'cunxuzhai') {
        urlDatail.push({ name: '二级市场', key: e.key, detailly: null });
      } else if (e.key == 'FinancialDemining') {
        urlDatail.push({ name: '财务排雷', key: e.key, detailly: null });
      } else if (e.key == 'RiskScreen') {
        urlDatail.push({ name: '风险初筛', key: e.key, detailly: null });
      } else if (e.key == 'RiskScreenInfo') {
        urlDatail.push({ name: '风险初筛报告', key: e.key, detailly: null });
      } else if (e.key == 'ABSProductAll') {
        urlDatail.push({ name: '产品大全', key: e.key, detailly: null });
      } else if (e.key == 'PooledAssets') {
        urlDatail.push({ name: '入池资产', key: e.key, detailly: null });
      } else if (e.key == "DaqoSecurities") {
        urlDatail.push({ name: '证券大全', key: e.key, detailly: null });
      } else if (e.key == "DurationOfSecurity") {
        urlDatail.push({ name: '证券存续期', key: e.key, detailly: null });
      } else if (e.key == "SecondaryTransaction") {
        urlDatail.push({ name: '二级成交', key: e.key, detailly: null });
      } else if (e.key == "StockStatistics") {
        urlDatail.push({ name: '证券存量统计', key: e.key, detailly: null });
      } else if (e.key == 'DurationAsset') {
        urlDatail.push({ name: '资产存续期', key: e.key, detailly: null });
      } else if (e.key == 'ABSCashflow') {
        urlDatail.push({ name: '归集现金流', key: e.key, detailly: null });
      } else if (e.key == 'CreditMeasures') {
        urlDatail.push({ name: '增信措施', key: e.key, detailly: null });
      } else if (e.key == 'Appraisement') {
        urlDatail.push({ name: '新发债估值', key: e.key, detailly: null });
      } else if (e.key == 'Statistics') {
        urlDatail.push({ name: '净融资统计', key: e.key, detailly: null });
      } else if (e.key == 'HighYieldBondResearch') {
        urlDatail.push({ name: '高收益债研究', key: e.key, detailly: null });
      } else if (e.key == 'IndepthResearch') {
        urlDatail.push({ name: '深度调研', key: e.key, detailly: null });
      } else if (e.key == 'QEBody') {
        urlDatail.push({ name: '主体基本面', key: e.key, detailly: null });
      } else if (e.key == 'Explain') {
        urlDatail.push({ name: '评级说明', key: e.key, detailly: null });
      } else if (e.key == 'BondCalculator') {
        urlDatail.push({ name: '债券计算器', key: e.key, detailly: null });
      } else if (e.key == 'HotspotAnalysis') {
        urlDatail.push({ name: '热点分析', key: e.key, detailly: null });
      } else if (e.key == "ParkTopic") {
        urlDatail.push({ name: '园区专题', key: e.key, detailly: null });
      }
      else {
        urlDatail.push({ name: '新页面', key: e.key, detailly: null });
      }
      dispatch(setRoutingAddress(urlDatail));
    } else {
      console.log(e.key);
    }
  };

  const navItems: any = [
    {
      label: <span>菜单</span>,
      key: 'home',
      icon: <AppstoreOutlined />,
      popupClassName: 'homeMainMenuPopup',
      popupOffset: [-100, -8],
      children: [
        ...MENU_LIST.map(item => {
          if (!item.onlyShowHome) {
            return {
              type: item.type,
              label: (
                <span style={{ color: 'rgba(255, 205, 135, 1)' }}>
                  <img className={style.homeMenuIcon} src={item.icon} />
                  {item.label}
                </span>
              ),
              key: item.key,
              children: [...item.children]
            }
          }
          return
        }),
      ]
    },

    ...MENU_LIST.map(item => {
      if (!item.onlyShowHome) {
        return {
          label: item.label,
          key: item.key,
          children: item.children
        }
      }
      return
    }),

  ];


  // 搜索结果高亮 search:搜索关键字
  const highlightText = (iconType: string, search: string) => {
    if (!search) return iconType;
    const reg = new RegExp(search, 'ig');
    const splitIconTypes = iconType.split(reg);
    const matchIconTypes = iconType.match(reg) as Array<string>;
    return (
      <React.Fragment>
        {
          matchIconTypes?.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <span>{splitIconTypes[index]}</span>
                <span className={style.searchKey}>{matchIconTypes[index]}</span>
              </React.Fragment>
            )
          })
        }
        <span>
          {splitIconTypes[splitIconTypes.length - 1] || ''}
        </span>
      </React.Fragment>
    )
  };
  // 用户退出登录，清token
  const onLogout = () => {

    dispatch(logout());

  }
  const content = (
    <div className={style.contentwrapper}>
      <div className={style.contInner}>
        <Avatar
          size={40}
          src={avatar}
          icon={<UserOutlined />}
        />
        <div className={style.userinfo}>
          <div className={style.username}>{userInfo?.data?.name}</div>
          <div className={style.accountType}>{userInfo?.data?.roleList[0]?.name}</div>
        </div>
      </div>
      <Divider />
      <div className={style.userItem} onClick={() => setUserModalOpen(true)}><Space><UserOutlined />个人中心</Space></div>
      <div className={style.userItem} onClick={onLogout}><Space><LogoutOutlined style={{ transform: 'rotate(-90deg)', }} />退出</Space> </div>
    </div>
  );
  const Focus = () => {
    setwidthUp(true)
  }
  const Blur = () => {
    setwidthUp(false)
  }
  const CloseOther = () => {
    let urlDatail: any = _.cloneDeep(RoutingAddress);
    console.log(CheckedTag)

    urlDatail = urlDatail.filter((v: any) => {
      return v.key == CheckedTag || v.key == "homePage";
    });
    dispatch(setRoutingAddress(urlDatail));
  }
  const CloseAll = () => {
    let urlDatail: any = _.cloneDeep(RoutingAddress);
    urlDatail = urlDatail.filter((v: any) => {
      return v.key == "homePage";
    });
    dispatch(setCheckedTag("homePage"));
    dispatch(setRoutingAddress(urlDatail));
  }
  const contentClose = (
    <div className={style.CloseButton} style={{ width: "78px" }}>
      <div className={style.CloseButton1} onClick={CloseAll}>关闭全部</div>
      <div className={style.CloseButton2} onClick={CloseOther}>关闭其他</div>
    </div>
  )
  return (
    <div className={style.blankHeader}>
      <img src={logo} className={style['logo']} alt="" />
      <Menu
        onClick={onClick}
        multiple={true}
        selectedKeys={[current]}
        mode="horizontal"
        items={navItems}
        selectable={false}
        // openKeys={['home']} // 调试样式用
        // subMenuOpenDelay={99999} // 调试样式用
        style={{
          width: '100%',
          minWidth: '500px',
          height: '48px',
          lineHeight: '48px',
          border: 'none',
          background: '#15151A'
        }}
      />
      <div
        style={{
          width: '80%',
          height: '2px',
          background: '#15151A',
          position: 'absolute',
          top: '47px'
        }}
      ></div>
      <div className={style.searchBox}>
        <Select
          {...props}
          showSearch
          value={value}
          placeholder={"搜索主体/债券关键字"}
          style={{
            width: widthUp ? '240px' : '170px',
            textAlign: 'left', borderRadius: '2px',
            paddingRight: "30px", background: "#212129",
          }}
          defaultActiveFirstOption={false}
          suffixIcon={<img src={searchIcon} alt="" className={style.IconImg} />}
          filterOption={filterOption}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          options={data || []}
          onFocus={Focus}
          onBlur={Blur}
          // open={true}
          allowClear={true}
          onClear={() => handleChange(null)}
          dropdownStyle={{ width: '260px', padding: '0px 0px', borderRadius: '0px', background: "rgba(46,46,56,1)" }}
          popupClassName={style.searchPopup}
        />
        {/* {
          value == null ? <img src={searchIcon} alt="" className={style.IconImg} /> : ""
        } */}
        <img src={remindIcon} className={style.remindIconImg} alt="" />
      </div>

      <div className={style.userWrapper}>
        <Space wrap size={16}>
          <Popover placement="bottomRight" color='#2E2E38' content={content} arrow={true} overlayClassName={style.popWrapper}>
            <Avatar
              src={avatar}
              icon={<UserOutlined />}
            />
            <span className={style.nickname}>{userInfo?.data?.name}</span><DownOutlined style={{ fontSize: '11px' }} />
          </Popover>
        </Space>
      </div>

      <Popover
        content={contentClose}
        title=""
        placement="bottom"
        arrow={false}
        overlayClassName={style.popWrapper}
      >
        <Tag color="rgba(46, 46, 56, 1)" className={style.CloseButton}>
          关闭标签
          <img
            src={pullDon}
            style={{
              width: '15px',
              height: '15px',
              marginLeft: "5px"
            }}
          />
        </Tag>
      </Popover>
      <UserCenter open={userModalOpen} onCancel={() => setUserModalOpen(false)} />
    </div>
  );
}

export default BlankHeader;
