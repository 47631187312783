
import searchIcon from '@/assets/icon/搜索小.svg';
import remindIcon from '@/assets/icon/提醒.svg';
import jiashicang from '@/assets/icon/home/jiashicang.svg';
import zthx from '@/assets/icon/home/zthx.svg';
import zqsc from '@/assets/icon/home/zqsc.svg';
import abs from '@/assets/icon/home/abs.svg';
import zhhy from '@/assets/icon/home/zhhy.svg';
import yqzx from '@/assets/icon/home/yqzx.svg';
import dwsj from '@/assets/icon/home/dwsj.svg';
import ztyj from '@/assets/icon/home/ztyj.svg';
import lcfx from '@/assets/icon/home/lcfx.svg';
import FK from '@/assets/icon/home/FK.svg';
import TZRJL from '@/assets/icon/home/TZRJL.svg';

export interface IMenuItem {
  label: string; // 导航文字
  star: boolean; // 是否收藏（点亮星星）
  type: string; // 标识类型
  key: string; // 唯一标识, 前端遍历、跳转页面用
  id?: string; // 添加常用功能，传后端
}
export interface IMenu {
  label: string; // 导航文字
  key: string; // 唯一标识, 前端遍历、跳转页面用
  onlyShowHome?: boolean; // 是否只在首页左侧栏显示
  type: string; // 类型
  icon?: any; // 图标
  children: IMenuItem[];
  id?: string; // 添加常用功能，传后端

}

// 导航菜单统一配置
export const MENU_LIST: IMenu[] = [
  {
    label: '常用',
    onlyShowHome: true,
    key: 'collection',
    type: 'group',
    children: [
      // { label: '综合屏', star: false, type: '', key: '0' },
      { label: 'QE评级', star: false, type: 'star', key: 'QErate', id: '3' },
      { label: '自选监控', star: false, type: '', key: 'sd', id: '1' }
    ]
  },
  {
    label: '驾驶舱',
    key: 'dashboard',
    type: 'group',
    icon: jiashicang,
    children: [
      { label: '可投库', star: false, type: 'new', key: 'investDecision', id: '2' },
      { label: '自选监控', star: false, type: '', key: 'OptionalMonitoring', id: '1' },

    ]
  },
  {
    label: '主体看板',
    key: 'SubMenu',
    type: 'group',
    icon: zthx,
    children: [
      { label: 'QE评级', star: false, type: 'star', key: 'QErate', id: '3' },
      { label: '主体基本面', star: false, type: '', key: 'QEBody', id: '4' },
      { label: '评级变动', star: false, type: '', key: 'RatingChange', id: '5' },
      { label: '信用画像', star: false, type: '', key: 'ComingSoon', id: '6' },
    ]
  },
  {
    label: '债券市场',
    key: 'CreditDebt',
    type: 'group',
    icon: zqsc,
    children: [
      { label: '新发债估值', star: false, type: '', key: 'Appraisement', id: '28' },
      { label: '二级市场', star: false, type: 'star', key: 'cunxuzhai', id: '7' },
      { label: '高收益债研究', star: false, type: '', key: 'HighYieldBondResearch', id: '27' },
      { label: '净融资统计', star: false, type: '', key: 'Statistics', id: '66' },
      // { label: '可转债', star: false, type: '', key: 'CreditDebt-4' }
    ]
  },
  // {
  //   label: 'ABS专题',
  //   key: 'ABS',
  //   type: 'group',
  //   icon: abs,
  //   children: [
  //     { label: '数据中心', star: false, type: '', key: 'ABSProducts', id: '30'  },
  //     { label: '资产穿透', star: false, type: '', key: 'ABS-2', id: '31'  },
  //     // { label: '价格计算', star: false, type: '', key: '13' },
  //   ]
  // },
  {
    label: 'ABS中心',
    key: 'ABSDataCenter',
    type: 'group',
    icon: abs,
    children: [
      { label: '产品大全', star: false, type: '', key: 'ABSProductAll', id: '30' },
      { label: '资产存续期', star: false, type: '', key: 'DurationAsset', id: '31' },
      { label: '入池资产', star: false, type: '', key: 'PooledAssets', id: '37' },
      { label: '归集现金流', star: false, type: '', key: 'ABSCashflow', id: '38' },
      { label: '证券大全', star: false, type: '', key: 'DaqoSecurities', id: '39' },
      { label: '证券存续期', star: false, type: '', key: 'DurationOfSecurity', id: '40' },
      { label: '二级成交', star: false, type: '', key: 'SecondaryTransaction', id: '41' },
      { label: '证券存量统计', star: false, type: '', key: 'StockStatistics', id: '42' },
      { label: '增信措施', star: false, type: '', key: 'CreditMeasures', id: '43' },
      // { label: '价格计算', star: false, type: '', key: '13' },
    ]
  },
  {
    label: '投资人交流',
    key: 'InvestorExchange',
    type: 'group',
    icon: TZRJL,
    children: [
      { label: '深度调研', star: false, type: '', key: 'IndepthResearch', id: '35' },
      { label: '投资人会客厅', star: false, type: '', key: 'ReceptionRoom', id: '48' }
    ]
  },
  {
    label: '利差分析',
    key: 'SpreadAnalysis',
    type: 'group',
    icon: lcfx,
    children: [
      { label: '区域利差', star: false, type: 'new', key: 'RegionalSpread', id: '20' },
      { label: '行业利差', star: false, type: 'new', key: 'IndustrySpread', id: '19' },
      // { label: '等级利差', star: false, type: '', key: 'SpreadAnalysis-3' }
    ]
  },
  {
    label: '风控工具',
    key: 'RiskControlTool',
    type: 'group',
    icon: FK,
    children: [
      { label: '风险初筛', star: false, type: 'new', key: 'RiskScreen', id: '21' },
      { label: '财务排雷', star: false, type: 'new', key: 'FinancialDemining', id: '22' },
      { label: '债券计算器', star: false, type: '', key: 'BondCalculator', id: "74" }
    ]
  },
  {
    label: '宏观行业',
    key: 'IntelligentIndustry',
    type: 'group',
    icon: zhhy,
    children: [
      { label: '宏观研究', star: false, type: 'star', key: 'MacroeconomicAnalysis', id: '62' },
      { label: '智慧城投', star: false, type: 'star', key: 'Wisdom', id: '8' },
      // { label: '智慧产业', star: false, type: '', key: 'IntelligentIndustry-2', id: '32' },
      { label: '智慧金融', star: false, type: '', key: 'SmartFinance', id: '33' },
    ]
  },
  {
    label: '舆情资讯',
    key: 'Information',
    type: 'group',
    icon: yqzx,
    children: [
      { label: '舆情精选', star: false, type: '', key: 'jingxuan', id: '11' },
      { label: '公告信息', star: false, type: '', key: 'gonggao', id: '12' },
      { label: '政策热点', star: false, type: '', key: 'zhengce', id: '34' }
    ]
  },
  {
    label: '多维数据',
    key: 'Multidimensional',
    type: 'group',
    icon: dwsj,
    children: [
      { label: '区域数据', star: false, type: '', key: 'AreaData', id: '13' },
      { label: '经营数据', star: false, type: '', key: 'BusinessData', id: '14' },
      // { label: '企业财务数据', star: false, type: '', key: 'Multidimensional-3' }
    ]
  },
  {
    label: '专题研究',
    key: 'ResearchExchange',
    type: 'group',
    icon: ztyj,
    children: [
      { label: '热点分析', star: false, type: '', key: 'HotspotAnalysis', id: 'null' },
      { label: '园区专题', star: false, type: '', key: 'ParkTopic', id: '73' },
      { label: '研究报告', star: false, type: '', key: 'ResearchReport', id: '15' },
      { label: '违约专题', star: false, type: '', key: 'BreakaAontract', id: '16' },
      { label: '非标风险', star: false, type: '', key: 'NonStandardRisk', id: '17' },
      { label: '商票逾期', star: false, type: '', key: 'CommercialBillsOverdue', id: '18' },
    ]
  }
  // {
  //   label: '评级工具',
  //   key: 'RatingTool',
  //   type: 'group',
  //   icon: QEpjgj,
  //   children: [
  //     { label: '城投企业', star: false, type: '', key: 'RatingTool-1' },
  //     { label: '产业企业', star: false, type: '', key: 'RatingTool-2' },
  //     { label: '金融机构', star: false, type: '', key: 'RatingTool-3' }
  //   ]
  // },
]

export const MENU_LEFT: IMenu[] = [
  {
    label: '常用',
    onlyShowHome: true,
    key: 'collection',
    type: 'group',
    children: [
      // { label: '综合屏', star: false, type: '', key: '0' },
      { label: 'QE评级', star: false, type: 'star', key: 'QErate', id: '3' },
      { label: '自选监控', star: false, type: '', key: 'sd', id: '1' }
    ]
  },
  {
    label: '驾驶舱',
    key: 'dashboard',
    type: 'group',
    icon: jiashicang,
    children: [
      { label: '可投库', star: false, type: 'new', key: 'investDecision', id: '2' },
      { label: '自选监控', star: false, type: '', key: 'OptionalMonitoring', id: '1' },
    ]
  },
  {
    label: '主体',
    key: 'SubMenu',
    type: 'group',
    icon: zthx,
    children: [
      { label: 'QE评级', star: false, type: 'star', key: 'QErate', id: '3' },
      { label: '主体基本面', star: false, type: '', key: 'QEBody', id: '4' },
      { label: '评级变动', star: false, type: '', key: 'RatingChange', id: '5' },
      { label: '信用画像', star: false, type: '', key: 'ComingSoon', id: '6' },
      // { label: '主体报告', star: false, type: '', key: 'SubMenu-3', id: '27' },
      // { label: '风险监控', star: false, type: '', key: 'SubMenu-4' }
    ]
  },
  {
    label: '债券',
    key: 'CreditDebt',
    type: 'group',
    icon: zqsc,
    children: [
      { label: '新发债估值', star: false, type: '', key: 'Appraisement', id: '28' },
      { label: '二级市场', star: false, type: 'star', key: 'cunxuzhai', id: '7' },
      { label: '高收益债研究', star: false, type: '', key: 'HighYieldBondResearch', id: '27' },
      { label: '净融资统计', star: false, type: '', key: 'Statistics', id: '66' },
      { label: '债券计算器', star: false, type: '', key: 'BondCalculator', id: "74" },
      // { label: '可转债', star: false, type: '', key: 'CreditDebt-4' }
    ]
  },
  // {
  //   label: 'ABS专题',
  //   key: 'ABS',
  //   type: 'group',
  //   icon: abs,
  //   children: [
  //     { label: '数据中心', star: false, type: '', key: 'ABSProducts', id: '30'  },
  //     { label: '资产穿透', star: false, type: '', key: 'ABS-2', id: '31'  },
  //     // { label: '价格计算', star: false, type: '', key: '13' },
  //   ]
  // },
  {
    label: 'ABS中心',
    key: 'ABSDataCenter',
    type: 'group',
    icon: abs,
    children: [
      { label: '产品大全', star: false, type: '', key: 'ABSProductAll', id: '30' },
      { label: '资产存续期', star: false, type: '', key: 'DurationAsset', id: '31' },
      { label: '入池资产', star: false, type: '', key: 'PooledAssets', id: '37' },
      { label: '归集现金流', star: false, type: '', key: 'ABSCashflow', id: '38' },
      { label: '证券大全', star: false, type: '', key: 'DaqoSecurities', id: '39' },
      { label: '证券存续期', star: false, type: '', key: 'DurationOfSecurity', id: '40' },
      { label: '二级成交', star: false, type: '', key: 'SecondaryTransaction', id: '41' },
      { label: '证券存量统计', star: false, type: '', key: 'StockStatistics', id: '42' },
      { label: '增信措施', star: false, type: '', key: 'CreditMeasures', id: '43' },
      // { label: '价格计算', star: false, type: '', key: '13' },
    ]
  },
  {
    label: '交流',
    key: 'InvestorExchange',
    type: 'group',
    icon: ztyj,
    children: [
      { label: '深度调研', star: false, type: 'new', key: 'IndepthResearch', id: '35' },
      { label: '投资人会客厅', star: false, type: 'star', key: 'ReceptionRoom', id: '48' }
    ]
  },
  {
    label: '利差',
    key: 'SpreadAnalysis',
    type: 'group',
    icon: lcfx,
    children: [
      { label: '区域利差', star: false, type: 'new', key: 'RegionalSpread', id: '20' },
      { label: '行业利差', star: false, type: 'new', key: 'IndustrySpread', id: '19' },
      // { label: '等级利差', star: false, type: '', key: 'SpreadAnalysis-3' }
    ]
  },
  {
    label: '风控',
    key: 'RiskControlTool',
    type: 'group',
    icon: lcfx,
    children: [
      { label: '风险初筛', star: false, type: 'new', key: 'RiskScreen', id: '21' },
      { label: '财务排雷', star: false, type: 'new', key: 'FinancialDemining', id: '22' },
      { label: '债券计算器', star: false, type: '', key: 'BondCalculator', id: "74" }
    ]
  },
  {
    label: '行业',
    key: 'IntelligentIndustry',
    type: 'group',
    icon: zhhy,
    children: [

      { label: '宏观研究', star: false, type: 'star', key: 'MacroeconomicAnalysis', id: '62' },
      { label: '智慧城投', star: false, type: 'star', key: 'Wisdom', id: '8' },
      { label: '智慧金融', star: false, type: '', key: 'SmartFinance', id: '33' }
    ]
  },
  {
    label: '资讯',
    key: 'Information',
    type: 'group',
    icon: yqzx,
    children: [
      { label: '舆情精选', star: false, type: '', key: 'jingxuan', id: '11' },
      { label: '公告信息', star: false, type: '', key: 'gonggao', id: '12' },
      { label: '政策热点', star: false, type: '', key: 'zhengce', id: '34' }
    ]
  },
  {
    label: '数据',
    key: 'Multidimensional',
    type: 'group',
    icon: dwsj,
    children: [
      { label: '区域数据', star: false, type: '', key: 'AreaData', id: '13' },
      { label: '经营数据', star: false, type: '', key: 'BusinessData', id: '14' },
      // { label: '企业财务数据', star: false, type: '', key: 'Multidimensional-3' }
    ]
  },
  {
    label: '研究',
    key: 'ResearchExchange',
    type: 'group',
    icon: ztyj,
    children: [
      { label: '热点分析', star: false, type: '', key: 'HotspotAnalysis', id: 'null' },
      { label: '园区专题', star: false, type: '', key: 'ParkTopic', id: '73' },
      { label: '研究报告', star: false, type: '', key: 'ResearchReport', id: '15' },
      { label: '违约专题', star: false, type: '', key: 'BreakaAontract', id: '16' },
      { label: '非标风险', star: false, type: '', key: 'NonStandardRisk', id: '17' },
      { label: '商票逾期', star: false, type: '', key: 'CommercialBillsOverdue', id: '18' },
    ]
  },
  // {
  //   label: '评级工具',
  //   key: 'RatingTool',
  //   type: 'group',
  //   icon: QEpjgj,
  //   children: [
  //     { label: '城投企业', star: false, type: '', key: 'RatingTool-1' },
  //     { label: '产业企业', star: false, type: '', key: 'RatingTool-2' },
  //     { label: '金融机构', star: false, type: '', key: 'RatingTool-3' }
  //   ]
  // },
]
