import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUserInfo,
  selectPermission,
} from '@/store/modules/app';
import style from './index.module.less';
import { Badge, Tooltip } from 'antd';
const ADMIN = '管理员' // 管理员拥有全部权限
const PERMISSION = [
  {
    name: '驾驶舱',
    id: 3,
    children: [
      {
        name: '可投库'
      },
      {
        name: '自选监控'
      },
    ]
  },
  {
    name: '主体看板',
    id: 4,
    children: [
      {
        name: 'QE评级'
      },
      {
        name: 'QE评级导出'
      },
      {
        name: '主体基本面'
      },
      {
        name: '评级变动'
      },
      {
        name: '评级变动导出'
      },
      {
        name: '信用画像'
      }
    ]
  },
  {
    name: '债券市场',
    id: 5,
    children: [
      {
        name: '新发债估值'
      },
      {
        name: '二级市场'
      },
      {
        name: '高收益债研究'
      },
      {
        name: '净融资统计'
      },
    ]
  },
  {
    name: 'ABS中心',
    id: 14,
    children: [
      {
        name: '产品大全'
      },
      {
        name: '资产存续期'
      },
      {
        name: '入池资产'
      },
      {
        name: '归集现金流'
      },
      {
        name: '证券大全'
      },
      {
        name: '证券存续期'
      },
      {
        name: '二级成交'
      },
      {
        name: '证券存量统计'
      },
      {
        name: '增信措施'
      },
    ]
  },

  {
    name: '投资人交流',
    id: 15,
    children: [
      {
        name: '深度调研'
      },
      {
        name: '投资人会客厅'
      },
    ]
  },
  {
    name: '利差分析',
    id: 10,
    children: [
      {
        name: '行业利差'
      },
      {
        name: '区域利差'
      },
    ]
  }, {
    name: '风控工具',
    id: 11,
    children: [
      {
        name: '风险初筛'
      },
      {
        name: '财务排雷'
      },
      {
        name: '债券计算器'
      },
    ]
  },
  {
    name: '宏观行业',
    id: 6,
    children: [
      {
        name: '宏观研究'
      },
      {
        name: '智慧城投'
      },
      {
        name: '区域城投导出'
      },
      {
        name: '智慧金融'
      }
    ]
  },
  {
    name: '舆情资讯',
    id: 7,
    children: [
      {
        name: '精选舆情'
      },
      {
        name: '公告信息'
      },
      {
        name: '政策热点'
      },
    ]
  },
  {
    name: '多维数据',
    id: 8,
    children: [
      {
        name: '区域数据'
      },
      {
        name: '经营数据'
      },
    ]
  },
  {
    name: '专题研究',
    id: 9,
    children: [
      {
        name: '热点分析'
      },
      {
        name: '园区专题'
      },
      {
        name: '研究报告'
      },
      {
        name: '违约专题'
      },
      {
        name: '非标风险'
      },
      {
        name: '商票逾期'
      },
    ]
  },
]
// 用户个人权限
const UserPermission = (props: any) => {
  const { checked } = props
  const dispatch = useDispatch()
  const permissions: any = useSelector(selectPermission)
  const userInfo: any = useSelector(selectUserInfo)
  const [list, setList] = useState([])

  useEffect(() => {
    if (permissions) {
      // 全量已开通未开通所有权限
      const allPermissions = PERMISSION.map(p => ({
        ...p,
        children: p.children.map(c => {
          const pm: any = { ...c }
          permissions?.userPermissions?.forEach(item => {
            if (c.name == item.name) {
              pm.tag = item.tag
              pm.hasPermission = true
            }
            if (userInfo?.data?.roleList[0].name === ADMIN) {
              pm.hasPermission = true
            }
          })
          return pm
        })
      }))

      console.log(allPermissions)
      // 用户已开通的权限
      const uPermissions = allPermissions.map(item => ({ ...item, children: item.children.filter(child => child.hasPermission) }))
      setList(checked ? uPermissions : allPermissions)
    }
  }, [checked, permissions])

  const tooltipText = (child: any) => {
    if (child.tag != '' && child.tag != 'null' && child.tag != null) {
      if (child.name === '风险初筛') {
        return '当前剩余点数为' + child.tag
      } else if (child.tag == '试用') {
        return '试用权限仅展示近四年数据'
      }
    }
  }

  const renderFn = (role?: string) => {
    return list?.map(item => (
      <>{item?.children && item?.children.length ? <li key={item.name}>
        {item?.children && item?.children.length ? <label className={style.label}>{item.name}</label> : null}
        <span className={style.spanWrapper}>
          {
            item?.children && item?.children?.map((child) =>

              <span className={role == ADMIN ? style.active : child.hasPermission ? style.active : ''} key={child.name} >
                <Tooltip title={tooltipText(child)} >
                  <label>{child.name}
                    {
                      child.tag != '' && child.tag != 'null' && child.tag != null && child.tag !== '正式' ?
                        <span className={style.tag}>{child.tag}</span> : null}
                  </label>
                </Tooltip>
              </span>

            )
          }
        </span>
      </li> : null}</>))
  }

  return <ul className={style.userPermission}>
    {
      // 管理员拥有全部权限
      userInfo?.data?.roleList[0].name === ADMIN ? renderFn(ADMIN) : renderFn()
    }

  </ul>
}

export default UserPermission;
